/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.ProductTab {
    &-Item {
        margin: 0;
        padding-inline-start: 0;

        &:last-child {
            margin: 0;
            padding: 0;
        }

        &::before {
            content: '';
        }

        &_isActive {
            background-color: transparent;
            .ProductTab-Button {
                background-color: #{$default-background-dark};
                font-weight: bold;
            }
            
        }
    }

    &-Button {
        background-color: #{$white};
        text-transform: uppercase;
        font-size: 16px;
        // line-height: 52px;
        font-weight: 600;
        margin: 0 5px;
        // padding: 0 82px;
        
    }
}