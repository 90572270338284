/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
/* Icons */
.ProductActions-IconsWrapper {
    .ProductIcon {
        &-Btn {
            .ProductIcon {
                &-Tooltip {
                    padding-bottom: 7px;
                }
            }
        }
    }
}

@media (max-width: 810px) {
    .Popup_isVisible.SPProductIconsPopup {
        z-index: 400;
        position: fixed;
        background-color: var(--popup-background);
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: all;
        opacity: 1;
        width: 100vw;
        height: 100vh;
        top: 0;
        .Popup-Content {
            background-color: var(--popup-content-background);
            border-radius: 5px;
            padding: var(--popup-content-padding);
            min-width: unset;
            width: calc(100vw - 2rem);
            max-height: 80%;
            overflow-y: auto;
            .Popup-Header {
                display: block;
                z-index: 5;
                .Popup-CloseBtn { top: -4px !important; }
            }
            .NotificationList {
                width: auto;
            }
        }
    }
}
