/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #000000;
$default-background: #FDF5E8;
$default-background-dark: #F9E4C5;
$default-primary-base-color: #004c9b;
$default-primary-dark-color: darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$font-muli: 'Muli', sans-serif;
$font-pompiere: 'Pompiere', sans-serif;
$font-dancingScript: 'Dancing Script', sans-serif;
$font-standard-size: 62.5%;
$page-background: #FDF5E8;
:root {
    --color-karaka: #{$black};
    --overlay-background: var(--secondary-base-color);
    --overlay-desktop-border-color: var(--primary-divider-color);
    --overlay-content-background: #{$default-background};
    --my-account-background: #{$default-background};
    --my-account-content-background: #{$default-background};
    --my-account-tab-list-background: #{$default-background};
    --popup-content-background: #{$default-background};
    --color-gray: #{$default-background-dark};
    --option-background-color: #{$default-background};
    --category-details-background: #{$default-background};
    --category-details-background: #{$default-background};
    --default-button-radius: 10px;
}
$homepage-width: 1440px;
$product-title-size: 48px;
$product-short-desc-size: 18px;
$product-add-button-color: #0659a5;

.icon-play:before { content: '\e80c'; } /* '' */
$icon-play: '\e80c';