/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
:root {
    --tooltip-background: #000000;
  }

.ProductActions {
    padding-top: 15px;
    width: 100%;
    @include desktop {
        max-width: 628px;
        padding-top: 0;
    }
    &-Title {
        display: block;
        font-family: $font-pompiere;
        font-size: $product-title-size;
        font-weight: normal;
        text-transform: uppercase;
        line-height: 53px;
    }
    &-ShortDescription {
        @include mobile {
            line-height: normal;
            margin-bottom: 0;
        }
        h2 {
            font-weight: 400;
        }
        p {
            font-family: $font-muli;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
        }
        > div {
            font-family: $font-muli;
            font-size: 22px;
            line-height: normal;
            @include mobile {
                font-size: 22px;
            }
        }
        .description {
            font-family: $font-muli;
            font-size: 18px;
        }
    }

    &-FeaturesWrapper {
        .ProductFeature {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            margin-left: 21px;
            list-style: outside url('../../../public/images/icon-awesome-check.svg');
            margin-bottom: 10px;
            padding-left: 5px;
            &::before {
                content: unset;
                position: relative;
            }
            @include mobile {
                font-size: 18px;
                line-height: 25px;
                margin-bottom: 10px;
            }
        }
    }
    &-IconsWrapper {
        width: 100%;
        margin: 20px 0;
        border-top: 1px solid #707070;
        border-bottom: 1px solid #707070;
        padding: 33px 0;
        .ProductIcon {
            position: relative;
            display: inline-block;
            max-width: 30px;
            margin-left: 12px;
            margin-right: 12px;
            .Icon {
                img {
                    vertical-align: middle;
                    max-width: 100%;
                }
            }
            &-Tooltip {
                visibility: hidden;
                width: auto;
                position: absolute;
                top: calc(100% + 1rem);
                padding: 5px;
                border-radius: 6px;
                background-color: var(--tooltip-background);
                z-index: 99;
                span {
                    color: #fff;
                    text-align: center;
                    white-space: nowrap;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: -30%;
                    left: 10px;
                    transform: rotate(180deg);
                    border-width: 5px;
                    border-style: solid;
                    border-color: var(--tooltip-background) transparent transparent transparent;
                }
            }
            &:hover {
                cursor: pointer;
                .ProductIcon-Tooltip { visibility: visible; }
            }
        }
        .Button_likeLink {
            vertical-align: middle;
            padding-left: 25px;

        }
        .Button.Button_likeLink:not([disabled]):hover,
        .Button.Button_likeLink:not([disabled]):focus {
            padding-left: 25px;
        }
    }
    &-AddToCartWrapper {
        display: block;
        .AddToCart-Title {
            font-size: 18px;
            font-weight: bold;
            line-height: 22px;
            margin-bottom: 10px;
        }
        .ProductActions-AddToCart {
            background: $product-add-button-color;
            border-color: $product-add-button-color;
            &:hover {
                background: $product-add-button-color;
                border-color: $product-add-button-color;
                height: 46px;
            }
        }
    }
    &-priceperkilo {
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        text-transform: capitalize;
        @include mobile {
            order: 4;
        }
    }
    &-weightVariablity {
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        @include mobile {
            order: 5;
        }
    }
    &-Section_type_sku {
        flex-direction: column;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
    }
    &-PriceWrapper {
        .ProductPrice {
            font-size: 30px;
        }
    }

    &-Stock {
        &_stockClass_Out-of-stock {
            color: red;
        }
    }

    &-CustomerTradeUnit {
        display: inline-block;
        margin-left: 10px;
        text-transform: capitalize;
        @include mobile {
            margin: 0 10px 0 0;
            line-height: var(--button-height)
        }
    }
    &-LabelPrice {
        font-size: 18px;
    }

    @include desktop {
        .AddToCart.AddToCart_layout_grid {
            display: inline-block;
            width: auto;
            font-size: 16px;
            font-weight: 800;
            margin: 0;
            height: 46px;
            line-height: 40px;
            border-radius: 10px;
            padding-top: 1px;
            padding-left: 10px;
            padding-right: 10px;
            &:hover {
                border-radius: 10px !important;
                height: 46px;
                width: auto;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        &-Qty.Field.Field_type_text,
        &-Qty.Field.Field_type_number {
            display: inline-grid;
            justify-items: stretch;
            grid-template-columns: 55px 18px;
            border: 1px solid #707070;
            border-radius: 10px;
            background-color: $white;
            margin-right: 10px;
            height: 46px;
            width: 75px;
            vertical-align: top;

            input.ProductActions-Qty {
                grid-column-start: 1;
                grid-row-start: 1;
                grid-row-end: span 2;
                line-height: 44px;
                width: 100%;
                order: unset;
                font-size: 26px;
                font-weight: 800;
                height: auto;
                border-radius: 10px;
                padding-top: 2px;
                background: transparent;
            }
            button {
                grid-column-start: 2;
                grid-row-start: 2;
                height: 20px;
                border: none;
                width: 100%;
                order: unset;
                &:first-of-type {
                    grid-column-start: 2;
                    grid-row-start: 1;
                }
                svg {
                    height: 15px;
                    width: 15px;
                    fill: $black;
                }
            }
        }
        &-Qty.Field.Field_type_text {
            grid-template-columns: 100%;
            input.ProductActions-Qty {
                border: 0;
                padding: 0.4rem;
                text-align: center;

            }
        }
    }
    @include mobile {
        display: flex;
        flex-direction: column;
        &-Section_type_short {
            order: 2;
        }
        &-ShortDescription {
            p {
                font-size: 22px;
                line-height: 27px;
                font-weight: 400;
            }
        }
        &-FeaturesWrapper {
            order: 8;
            margin-top: 30px;
        }
        &-IconsWrapper {
            order: 9;
            .Button_likeLink {
                display: block;
                padding: 15px 0 0 12px;
                @include mobile {
                    display: none;
                }
            }
        }
        &-TierPrices {
            order: 5;
        }
        &-ActionsWrapper {
            order: 4;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;
            .ProductActions-Schema {
                order: 1;
                .ProductPrice {
                    font-size: 30px;
                }
            }
            .ProductActions-Section_type_sku {
                order: 1;
                align-items: flex-start;
                font-weight: 400;
            }
        }
        .ProductActions-ShortDescription>h2{
            font-size: 22px;
            font-weight: normal;
            font-family: "Muli", sans-serif;
            line-height: normal;
            margin-top: 0px;
            margin-bottom: 0px;
        }
        .ProductActions-Schema {
            order: 1;
            margin-top: 10px;
            margin-bottom: 10px;
            .ProductPrice {
                font-size: 30px;
            }
        }
        .ProductActions-LabelPrice {
            order: 10;
            padding-top: 5px;
        }
    }

    &-AttributesWrapper {
        @include mobile {
            margin-bottom: 20px;
            margin-top: 20px;
            order: 2;
        }
    }
    &-Qty {
        button {
            @include mobile {
                border-radius: 6px;
                border-color: black;
                .MinusIcon_isPrimary,
                .AddIcon_isPrimary {
                    fill: black;
                }
            }
        }
    }
    &-Stock {
        @include mobile {
            margin-bottom: 0;
        }
    }
}

.ProductConfigurableAttributes-Title{
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 10px;
    @include mobile {
        font-weight: bold;
        margin-top: 5px;
    }
}

.ProductAttributeValue-String {
    width: auto;
    height: 50px;
    background: #c0c0c0;
    color: black;
    font-size: 22px;
    line-height: 45px;
    border-radius: 10px;
    padding-top: 2px;
}
.ProductAttributeValue-String_isSelected {
    background: #0C8248;
    color: white;
    width: auto;
    height: 50px;
    font-size: 22px;
    line-height: 45px;
    border-radius: 10px;
    border-color: #0C8248;
    font-weight: bold;
}
