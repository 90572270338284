/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/pagebuilder';
.ProductLabel {
  &-LabelText {
    position: absolute;
    white-space: nowrap;
    z-index: 3;
    line-height: 36px;
    text-align: center;
    width: 100%;
  } 
  &-Image { 
    .Image {
      &-Image {
        object-fit: cover;
        @include mobile {
          width: 80% !important;
          height: auto !important;
        }
      }
    }
  }
}