/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
:root {
    --carousel-width: 100%;
}

.ProductGallery {
    height: unset;
    width: 100%;
    @include desktop {
        max-width: 700px;
    }
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    @media (min-width: 1024px) and (max-width: 1280px) {
        min-width: auto;
    }
    &-Additional {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        &.ProductGallery-Additional_isImageZoomPopupActive {
            position: relative;
            left: 0;
            .CarouselScroll {
                &-ContentWrapper {
                    padding-top: 20.8%;
                }
            }
        }
        .CarouselScroll {
            margin-top: 5px;
            margin: 5px auto 0;
            position: relative;
            &-ContentWrapper {
                padding-top: 21.6%;
                position: relative;
            }
            &-Content {
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
            }
            &Arrow {
                display: none;
                position: absolute;
                margin: 0 4px;
                &_isPrevArrow {
                    left: -45px;
                }
                &_isNextArrow {
                    right: -45px;
                }
            }
            &Item {
                width: 33.33%;
                max-width: calc((100% - 18px) / 3);
                height: unset;
                // PR aspect-ratio: 227 / 151;
                padding: 0;
                border-radius: 0;
                // border: none; // enbale when cliet signs-off change to remove border
                @include desktop {
                    .ProductGalleryThumbnailImage {
                        padding: 0;
                        height: auto;
                        img {
                            background-color: #fdf5e8;;
                        }
                    }
                }
            }
        }
        .Image-Image {
            object-fit: cover;
        }
    }
    &-SliderWrapper {
        border: 1px solid #707070;
        flex: unset;
        width: 100%;
        height: unset;
        padding-top: 68.5%;
        position: relative;
        .ProductGallery-Slider {
            position: absolute;
            top: 0;
            width: 100%;
            .ProductGallery-SliderImage {
                height: 100%;
                .Image-Image {
                    object-fit: cover;
                }

            }
        }
        .Slider-Arrow {
            margin: 0 4px;
            &.Slider-Arrow_isPrev {
                left: -45px;
            }
            &.Slider-Arrow_isNext {
                right: -45px;
            }
        }

    }
    &-Slider {
        // Remove padding from thumbnail video - causing height to expand
        .VideoThumbnail-Thumbnail {
            padding-bottom: 0;
        }
    }
    .ChevronIcon {
        fill: black;
    }
}
