/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.ProductTabs {
    border-bottom: 9px solid #{$default-background-dark};
    @include desktop {
        justify-content: center;
    }

    &-Wrapper {
        margin-bottom: 0;
        border-bottom: 9px solid #{$default-background-dark};
        @include mobile {
            border-bottom: none;
            margin-left: -16px !important;
            margin-right: -16px !important;
            margin-top: 20px;
            > section {
                border-bottom: 1px solid #f0f0f0;
                &:first-child {
                    margin-top:20px;
                    border-top: 1px solid #f0f0f0;
                }
                .ProductInformation-Description {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .ContentWrapper {
            padding: 49px 15px;
            @include mobile {
                padding: 0;
            }
        }

        .ExpandableContent-Heading {
            text-transform: none;
            font-weight: 400;
            line-height: 26px;
            display: block;
            @include mobile {
                text-transform: uppercase;
                font-weight: 600;
            }
        }

        .ExpandableContent {
            border-block-end: 0;
            margin: 0;
            @media (min-width: 810px) {
                display: grid;
                align-content: center;
                justify-items: start;
                grid-template-columns: 1fr 3fr;
                align-items: start;
                grid-template-rows: -webkit-min-content -webkit-min-content;
                grid-template-rows: min-content min-content;

            }
            @include mobile {
                border-top: none;
                // background: white;
                // padding: 0 16px;
                margin-bottom: 5px;
                .AddIcon {
                    width: 15px;
                    height: 15px;
                }
                .MinusIcon {
                    width: 15px;
                    height: 15px;
                }
                &-Button {
                    padding: 10px 16px;
                }
                &-Content {
                    > div {
                        padding-bottom: 10px;
                        @include mobile {
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }
            }
            &-Content {
                margin: 0;
                line-height: 26px;

            }
        }

        & > :first-child {
            border-block-start: 0;
        }
    }
}