/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
:root {
  --option-background-color: #{$white};
  --option-check-mark-background: #{$black};
  --option-size: 32px;
  --option-text-color: #{$black}; // Move to root
  --option-border-color: var(--color-dark-gray);

  @include mobile {
      --option-border-color: var(--color-dark-gray);
  }
}
.ProductWeight {
  --button-background: initial;
  --button-color: initial;
  --button-padding: 0;
  --option-is-selected: 0;


  &-Title{
    margin-top: 24px;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 10px;
  }
  
  &-Content {
    text-align: center;
    margin-block-start: 2px;
    margin-block-end: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-inline-start: 3px;
    padding-block-start: 3px;

    span {
      display: inline-block;
      margin-bottom: 6px;
      padding: 0 6px 2px 6px;
      border-width: 1px;
      display: inline-block;
      min-height: var(--option-size);
      margin-inline-end: 12px;

      border-style: solid;
      min-width: var(--option-size);
      
      text-align: center;

      padding-top: 2px;

      --option-border-color: var(--primary-base-color);
      --option-text-color: var(--primary-base-color);

      background: #0C8248;
      color: white;
      width: auto;
      height: 50px;
      font-size: 22px;
      line-height: 45px;
      border-radius: 10px;
      border-color: #0C8248;
      font-weight: bold;
    }

  }
}