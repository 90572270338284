/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.ProductPage {
    background-color: #{$default-background};
    @media (min-width: 811px) {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
    }
    @media (min-width: 810px)
    and (max-width: 1000px) {
        &-Wrapper.ContentWrapper {
            grid-template-columns: 375px auto;
        }
    }
    @media (min-width: 1001px)
    and (max-width: 1160px) {
        &-Wrapper.ContentWrapper {
            grid-template-columns: 500px auto;
        }
    }
    @media (min-width: 1161px)
    and (max-width: 1300px) {
        &-Wrapper.ContentWrapper {
            grid-template-columns: 600px auto;
        }
    }
    @media (min-width: 1301px) {
        &-Wrapper.ContentWrapper {
            grid-template-columns: 700px auto;
        }
    }
    @include mobile {
        padding: 10px 16px 30px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }
    
}