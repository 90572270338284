/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.ProductLinks {
    &-List {
        @media (min-width: 811px) and (max-width: 1380px) {
        grid-template-columns: repeat(4, 1fr);
        }
    }
    &-Title {
        font-family: $font-pompiere;
        font-size: $product-title-size;
        font-weight: normal;
        text-align: center;
    }
    .ProductCard {
        padding-right: 0;
        &-Name {
            font-size: 18px;
            min-height: 65px;
        }
        &-Category {
            font-size: 8px;
        }
        &-Price {
            font-size: 23px;
        }
        .ProductPrice{
            &-HighPrice {
                font-size: 12px;
            }
            del {
                opacity: 1;
            }
        }
        @include mobile {
            &-Name {
                font-size: 13px;
                line-height: 23px;
                min-height: 55px;
            }
            &-Category {
                font-size: 6px;
            }
            &-Price {
                font-size: 16px;
            }
            .ProductPrice{
                &-HighPrice {
                    font-size: 8px;
                }
                del {
                    opacity: 1;
                }
                &_hasDiscount {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    del {
                        order: 2;
                        padding-left: 10px;
                    }
                    ins {
                        order: 1;
                    }
                }
            }
            &-Footer {
                .Button {
                    width: 46px;
                    background-size: contain;
                }
            }
        }
    }
}