/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CustomProductTab {
    display: grid;
    grid-template-columns: 30% auto;
    &-Result {
        display: grid;
        grid-template-columns: 30% auto;
        row-gap: 10px;

        @include mobile {
            grid-template-columns: 1fr 2fr;
            padding: 10px 0;
        }
    }
}


.CustomProductTab {
    font-size: 18px;
    line-height: 20px;

    &-Wrapper {
        padding: 0;

        @include desktop {
            grid-template-columns: 1fr !important;
            padding-block: 32px;
        }

        margin-bottom: 0;
        border-bottom: 9px solid #{$default-background-dark};
        @include mobile {
            border-bottom: none;
            > section {
                border-bottom: 1px solid #f0f0f0;
                &:first-child {
                    margin-top:20px;
                    border-top: 1px solid #f0f0f0;
                }
            }
        }
        .ContentWrapper {
            padding: 49px 15px;
            @include mobile {
                padding: 0;
            }
        }

        .ExpandableContent-Heading {
            text-transform: none;
            font-weight: 400;
            line-height: 26px;
            display: block;
            @include desktop {
                display: none !important;
            }
            @include mobile {
                text-transform: uppercase;
                font-weight: 600;
            }
        }

        .ExpandableContent {
            border-block-end: 0;
            margin: 0;
            font-size: 14px;
            @media (min-width: 810px) {
                display: grid;
                align-content: center;
                justify-items: start;
                grid-template-columns: 1fr 3fr;
                align-items: start;
                grid-template-rows: -webkit-min-content -webkit-min-content;
                grid-template-rows: min-content min-content;

            }
            @include mobile {
                border-top: none;
                .AddIcon {
                    width: 15px;
                    height: 15px;
                }
                .MinusIcon {
                    width: 15px;
                    height: 15px;
                }
                &-Button {
                    padding: 10px 0;
                    @include mobile {
                        padding: 10px 16px;
                        background: white;
                        &_isContentExpanded {
                            background: #f6e0bd;
                            .ExpandableContent-Heading {
                                font-weight: 800;
                            }
                        }
                    }
                }
                &-Content {
                    > div {
                        padding-bottom: 10px;
                        @include mobile {
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }
            }
            &-Content {
                margin: 0;
                line-height: 26px;
                width: 100%;
                @include mobile {
                    // margin: 0 -16px;
                    width: auto;
                    padding: 0 16px;
                    background: #FDF5E8;
                }

            }

        }

        
        & > :first-child {
            border-block-start: 0;
        }
    }
    &-AttributeLabel,
    &-AttributeValue {
        font-size: 1.8rem;
        line-height: normal;
        font-weight: 600;

        @include mobile {
            width: 90%;
            font-size: 1.4rem;
            font-weight: 800;
        }
    }

    &-ValueLabel {
        font-size: 18px;
        line-height: normal;
        @include mobile {
            font-size: 1.4rem;
            padding-left: 13px;
        }
    }
}