/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.ProductList {
    background: #{$default-background};
}
.ProductCard {
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
    padding: 0;
    box-shadow: 0px 9px 18px #00000029;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    &-FigureReview {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }
    &-Figure {
        .Image-Image {
            object-fit: cover;
        }
    }
    &-Content {
        @include mobile {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }
    &-Brand {
        display: none;
    }
    &-Name {
        padding-top: 12px;
        margin-top: 4px;
        border-top: 2px solid #EEEEEE;
        font-size: 20px;
        text-overflow: ellipsis;
        white-space: unset;
        min-height: 79px;
        @include desktop {
            min-height: 105px;
        }
        @include mobile {
            font-size: 13px;
            line-height: 23px;
            // min-height: 60px;
            min-height: 79px;
            padding-top: 6px;
            margin-bottom: 0;
        }
        @media (min-width: 490px)
        and (max-width: 768px) {
            font-size: 19px;
        }
    }
    &-Reviews {
        display: none;
    }
    &-Category {
        color: #B4B4B4;
        text-transform: uppercase;
        font-size: 19px;
        font-family: Verdana;
        letter-spacing: 1.1px;
        font-weight: 400;
        margin-bottom: 7px;
        @include mobile {
            font-size: 6px;
            line-height: 11px;
            margin-bottom: 0;
        }
    }
    .Out-Of-Stock {
        text-align: center;
        color: #B4B4B4;
        font-size: 19px;
        @include mobile {
            font-size: 13px;
        }
        padding: 15px 0 15px 0;
        @include mobile {
            padding: 19px 0 19px 0;
        }
    }
    &-PriceWrapper {
        min-height: 70px;
        justify-content: flex-start;
        padding-top: 15px;
    }
    &-Price {
        font-size: 28px;
        font-weight: 400;
        padding-bottom: 5px;
        @include mobile {
            font-size: 17px;
        }
        &-PriceValue {
            margin-top: 0;
        }
        .ProductPrice {
            &-Price {
                display: block;
                &Badge {
                    display: none;
                    line-height: normal;
                }
            }
        }
        del.ProductPrice-HighPrice {
            float: right;
            color: #B4B4B4;
            font-weight: 400;
            font-size: 15px;
            margin-left: 0;
            margin-right: 0;
            text-align: right;
            @include mobile {
                font-size: 9px;
                line-height: 17px;
            }
        }
        .weight {
            font-size: 13px;
            text-transform: capitalize;
        }
    }
    &:hover {
        box-shadow: none;
    }
    .ProductCard-Link {
        &:hover {
            box-shadow: none;
        }
    }
    &-Footer {
        justify-content: end;
        background: $white;
        margin-top: 0;
        .Button.AddToCart {
            margin-right: 15px;
            background-color: $white;
            border: none;
            background-image: url("../../../public/images/add_to_cart.svg");
            background-repeat: no-repeat;
            width:65px;
            height: 50px;
            min-width: auto;
            &:not([disabled]):hover {
                background-color: transparent;
                border: none;
            }
            span {
                display: none;
            }
        }
        .ProductCard-ProductActions {
            display: none;
        }
        .ProductCard-Link {
            flex-direction: row;
            justify-content: end;
            margin-right: 0;
        }
    }
    .ProductConfigurableAttributes  {
        display: none;
    }

    &-ProductIcons {
        display: flex;
        justify-content: flex-start;
        margin: 5px 15px;
        padding: 10px 0;
        border-top: 2px solid #EEEEEE;
        border-bottom: 2px solid #EEEEEE;

    }

    &-ProductIcon {
        display: inline-block;
        max-width: 30px !important;
        margin-left: 12px;
        margin-right: 12px;

        .Icon {
            img {
                max-width: 100%;
            }
        }

        @include mobile {
            max-width: 20px !important;
        }
    }
}

.DefaultProductCard-Price {
    font-size: 19px;
    font-weight: 400;
    color: #a9a9a9;
    span {
        text-decoration: line-through;
    }

    .weight {
        font-size: 13px;
        text-decoration: none;
    }
    @include mobile {
        font-size: 14px;
        .weight {
            font-size: 11px;
        }
    }
}

.HomePage {
    .ProductListWidget-Page {
        grid-column-gap: 8px;
    }
    .ProductCard {
        margin-left: 0 !important;
        &-Name {
            min-height: 79px;
            text-align: left;
            @include desktop {
                min-height: 131px;
            }
        }
        &-Category {
            text-align: left;
        }
        &-Price {
            line-height: 41px;
            text-align: left;
            .ProductPrice {
                &-Price {
                    display: block;
                    &Badge {
                        font-size: 11px;
                    }
                }
            }
            del.ProductPrice-HighPrice {
                font-size: 12px;
            }
        }
    }
}
